<template>
  <div>
    <content-template
      :menuName="`${$t('expertMatching.title')} Detail`"
      :backButton="true"
      @backToPage="moveToOther('Expert Matching Dashboard')"
    >
      <template #detail>
        <table class="table-expert-matching">
          <tr v-for="(t, i) in fields" :key="`task-${i}`">
            <td
              :class="`s-input-label s-ptb-10 ${
                isMobile ? 's-wd-100-min' : 's-wd-200-min'
              }`"
            >
              {{ $t(t.name) }}
            </td>
            <td class="s-ptb-10">:</td>
            <td
              class="s-ptb-10"
              v-if="t.variable == 'start_date' || t.variable == 'end_date'"
            >
              {{ convertDate(expert_matching[t.variable]) }}
            </td>
            <td class="s-ptb-10" v-else-if="t.variable == 'expertise'">
              <span
                class="s-text-black"
                v-for="(e, i) in expert_matching['expertise_detail']"
                :key="`expert-${i}`"
              >
                {{ $t(e.name) }}
                <span
                  class="s-text-black"
                  v-if="i < expert_matching['expertise_detail'].length - 1"
                  >|
                </span>
              </span>
            </td>
            <td class="s-ptb-10" v-else style="white-space: pre-wrap">
              {{ expert_matching[t.variable] }}
            </td>
          </tr>
        </table>
      </template>
    </content-template>
  </div>
</template>

<script>
import form from "@/mixins/form";
import ContentTemplate from "./contentTemplate.vue";
import { mapActions, mapGetters } from "vuex";
import { convertDate } from "@/common/timeformat";

export default {
  components: { ContentTemplate },
  props: ["inputType", "item"],
  mixins: [form],
  data() {
    return {
      edited_item: {
        title: "",
        creator_name: "",
        location: "",
        description: "",
        expertise_detail: [],
        start_date: "",
        end_date: "",
      },

      fields: [
        { name: "general.title", variable: "title" },
        { name: "feedbackForm.submittedBy", variable: "creator_name" },
        { name: "general.location", variable: "location" },
        { name: "general.description", variable: "description" },
        { name: "sidebar.expertise", variable: "expertise" },
        { name: "general.startDate", variable: "start_date" },
        { name: "general.endDate", variable: "end_date" },
        { name: "Status", variable: "status" },
      ],
      fieldsExpert: [
        { name: this.$t("expert.expertName"), variable: "expert_name" },
        { name: this.$t("expert.expertise"), variable: "expertise_detail" },
      ],
    };
  },
  created() {
    this.editItem();
  },
  mounted() {
    this.initData();
  },
  computed: {
    isMobile() {
      return this.$store.getters["getIsMobile"];
    },
    ...mapGetters({
      expert_matching: "expertmatching/getExpertMatching",
    }),
  },
  methods: {
    ...mapActions({
      setExpertMatching: "expertmatching/setExpertMatchingSingleData",
    }),

    async initData() {
      this.setLoadingPage(true);
      await this.setExpertMatching(this.$route.params.id);
      this.setLoadingPage(false);
    },
    editItem() {
      this.edited_item = Object.assign({}, this.item);
    },
    convertDate(value) {
      return convertDate(value);
    },
  },
};
</script>

<style scoped lang="scss">
.table-expert-matching {
  tr:hover {
    background-color: white !important;
  }
}
</style>
